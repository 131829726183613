import React, { useEffect, useState } from 'react';
import {
	Avatar,
	Dropdown,
	Layout,
	Menu,
	Spin,
	Button,
	Popover,
	notification,
	Badge,
	Drawer,
	Typography,
	Card
} from 'antd';
import { SmileOutlined, ScheduleOutlined } from '@ant-design/icons';
import SuperSearchNew from '../../Pages/search/components/SearchComponent';
import { Icon } from '@ant-design/compatible';
import { useNavigate, useLocation } from 'react-router-dom';
import authClient from '../../Auth/Auth';
import '../../App.less';
import {
	SidebarItemsCustomers,
	SidebarItemsHadmin,
	SidebarItemsLoginHadmmin
} from '../coreNav/SidebarItems';
import { useSelector, useDispatch } from 'react-redux';
import useGoogleAnalytics from '../AnalyticsGoogle/useGoogleAnalytics';
import { resetProfileRedux } from '../../store/auth/actions';
import Login from '../../Pages/auth/Login';
import auth from '../../Auth/Auth';
import AircallPhone from 'aircall-everywhere';
import moment from 'moment';
import { setAircallClient, showAircall } from '../../store/aircall/actions';
import { fetchTaskExpired, fetchTaskList } from '../../store/task/actions';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

function LayoutRoute(props) {
	const [collapse, setCollapse] = useState(false);
	const roles = useSelector((state) => state.auth.roles);
	const [loading, setLoading] = useState(true);
	const [profile, setProfile] = useState({});
	const [logeado, setLogeado] = useState(auth.getToken().length > 0);
	const [aircallStatusColor, setAircallStatusColor] = useState('red');
	const aircallVisible = useSelector((state) => state.aircall.visible);
	const [open, setOpen] = useState(false);

	let dispatch = useDispatch();
	let navigate = useNavigate();
	const location = useLocation();

	useGoogleAnalytics();
	const tasksExpired = useSelector((state) => state.task.setDataTaskExpired);

	useEffect(() => {
		const profile = authClient.getProfile();

		if (profile.type === 'risk') {
			dispatch(showAircall(true));
		}

		setProfile(profile);
		setLoading(false);
		dispatch(fetchTaskExpired(authClient.getToken(), true));
		//dispatch(fetchTaskExpired(authClient.getToken(), false));
	}, []);

	useEffect(() => {
		const profile = authClient.getProfile();

		const aircall = new AircallPhone({
			onLogin: () => {
				setAircallStatusColor('green');
				if (profile.type === 'risk') {
					dispatch(showAircall(false));
				}
			},
			onLogout: () => {},
			domToLoadPhone: '#aircall-phone',
			size: 'big'
		});

		aircall.on('outgoing_call', (callInfos) => {
			setAircallStatusColor('orange');
		});

		aircall.on('incoming_call', (callInfos) => {
			setAircallStatusColor('orange');
		});

		aircall.on('call_ended', (callInfos) => {
			setAircallStatusColor('green');
		});

		dispatch(setAircallClient(aircall));
	}, []);

	const onCollapse = (collapsed) => {
		setCollapse(collapsed);
	};

	const logOut = async () => {
		await dispatch(resetProfileRedux());
		logeado && navigate('/login');
	};

	const iconUser = <Icon type="user" />;

	const menu = (
		<Menu>
			<Menu.Item key="13">
				<SmileOutlined />
				<a target="_blank" href="https://app.factorialhr.com/">
					<span> Factorial HR</span>
				</a>
			</Menu.Item>
			<Menu.Item key="14" style={{ color: '#FF4D4F' }}>
				<Icon type="logout" />
				<span onClick={() => logOut()}> Cerrar sesión</span>
			</Menu.Item>
		</Menu>
	);

	const list_hadmin = SidebarItemsHadmin(profile.type, profile.managementCode);
	let render_list_hadmin = list_hadmin.map((item, index) => {
		return (
			<Menu.Item
				id={item.id}
				onClick={() => navigate(item.route)}
				key={item.route}
				icon={item.icon}>
				{item.name}
			</Menu.Item>
		);
	});

	let render_list_customer = SidebarItemsCustomers.map((item, index) => {
		return (
			<Menu.Item onClick={() => navigate(item.route)} key={item.route} icon={item.icon}>
				{item.name}
			</Menu.Item>
		);
	});

	let render_list_login_admin = SidebarItemsLoginHadmmin.map((item, index) => {
		if (roles.includes('hadmin')) {
			return (
				<Menu.Item onClick={() => navigate(item.route)} key={item.route} icon={item.icon}>
					{item.name}
				</Menu.Item>
			);
		}
	});

	let prof = auth.getProfile();
	const agent = logeado ? prof.name : 'H1 Administrador';

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}
	const searchItem = (location) => {
		if (
			location.includes('/operations') ||
			location.includes('/promoter-loan') ||
			location === '/budgetsSVH' ||
			location === '/callcenter' ||
			location.includes('/certificates')
		) {
			return '/operations/mortgages/list/active';
		} else if (location.includes('/tools')) {
			return '/tools/translations';
		} else if (location.includes('/banks')) {
			return '/banks';
		} else if (location.includes('/realestates')) {
			return '/realestates/realestates';
		} else if (location.includes('/preregister')) {
			return '/preregister';
		} else {
			return location;
		}
	};

	const contentAir = (
		<iframe
			id="llamar-al-cliente"
			allow="microphone; autoplay; clipboard-read; clipboard-write"
			src="https://phone.aircall.io?integration=generic"
			style={{
				width: 376,
				height: 666,
				borderColor: '#25d6d9',
				borderWidth: '4px 10px 5px'
			}}></iframe>
	);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const renderTypeTask = (task) => {
		let type = undefined;

		if (task.type === 'call') {
			type = 'Llamar';
		} else if (task.type === 'doc') {
			type = 'Pedir documentación';
		} else if (task.type === 'other') {
			type = 'Otro';
		} else if (task.type === 'claimoffer') {
			type = 'Reclamar Oferta';
		} else if (task.type === 'comdoc') {
			type = 'Compromiso de documentación';
		} else if (task.type === 'tag') {
			type = 'Etiquetar';
		} else if (task.type === 'claim') {
			type = 'Reclamar documentación';
		} else if (task.type === 'firstcall') {
			type = 'Primera llamada';
		} else if (task.type === 'followup') {
			type = 'Llamada de seguimiento';
		} else if (task.type === 'phpanalysis') {
			type = 'Promotor Analisis';
		} else if (task.type === 'customerqualification') {
			type = 'Cualificación de cliente';
		} else if (task.type === 'sendbanks') {
			type = 'Enviar a bancos';
		} else if (task.type === 'choosenoffer') {
			type = 'Selección de oferta';
		} else if (task.type === 'analysis') {
			type = 'Nueva operación en analisis';
		} else if (task.type === 'gibobsappraisal') {
			type = 'Tasacion con Gibobs';
		} else if (task.type === 'automaticofferexpires5days') {
			type = 'La oferta automática caduca a los 5 días';
		} else if (task.type === 'automaticofferexpires1days') {
			type = 'La oferta automática caduca en un 1 días';
		} else if (task.type === 'automaticofferexpires') {
			type = 'La oferta automática caduca';
		} else if (task.type === 'recommender') {
			type = 'Recomendador';
		} else {
			type = 'Reclamar tasación';
		}

		return type;
	};

	const callClient = () => {
		notification.open({
			message: 'En Llamada',
			description: contentAir,
			duration: 0,
			style: { width: 410 }
		});
	};

	if (auth.getToken().length > 0) {
		return (
			<Layout style={{ Height: '100vh', paddingTop: 0, background: '#fff' }}>
				<Sider
					collapsed={collapse}
					onCollapse={onCollapse}
					style={{
						//overflow: 'hidden',
						overflow: 'auto',
						height: '100vh',
						position: 'fixed',
						left: 0,
						right: 0,
						scrollbarColor: 'transparent'
					}}
					width={250}>
					<div className={profile.email.includes('@bancsabadell.com') ? 'logoS' : 'logo'}>
						{profile.email.includes('@bancsabadell.com') ? (
							<img src={'/images/sabadellPhoto.jpg'} alt="logo" />
						) : (
							<img
								src={'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png'}
								alt="logo"
							/>
						)}
					</div>
					<Menu
						theme="dark"
						selectedKeys={searchItem(location.pathname)}
						// defaultSelectedKeys={['1']}
						mode="inline">
						{render_list_hadmin}
						{
							//render_list_hadmin
						}
						{
							// render_list_customer
						}
					</Menu>
				</Sider>
				<Drawer
					width={320}
					title="Avisos pendientes"
					onClose={onClose}
					size={120}
					open={open}
					style={{ borderRadius: '16px 0 0 0', background: '#ECF7FC' }}
					className="drawer-pending-notices">
					<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
						Verás todos los avisos pendientes, se cerrarán automáticamente cuando la tarea se
						complete.
					</Text>
					<div style={{ marginTop: 16 }}>
						{tasksExpired !== undefined &&
							tasksExpired.length > 0 &&
							tasksExpired.map((task) => {
								if (task.notification && task.notified) {
									return (
										<Card
											className="card-notification"
											style={{ border: '1px solid #FAAD14', marginBottom: 8 }}>
											<div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
												<img src={'/images/remind.svg'} alt="remindIcon" height={21} width={21} />
												<Text style={{ fontSize: 16, fontWeight: 600, color: '#2F4858' }}>
													{task.name}
												</Text>
											</div>
											<div style={{ marginTop: 8, display: 'flex', flexDirection: 'column' }}>
												<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
													{task.description}
													<br />
													<a
														style={{ fontWeight: 600, color: '#FBC14F' }}
														target="_blank"
														href={`/operations/mortgages/${task.operationId}`}>
														{task.operationName}
													</a>
													<br />
													{renderTypeTask(task)}
												</Text>
											</div>
										</Card>
									);
								}
							})}
					</div>
				</Drawer>
				<Layout
					className="site-layout"
					style={{
						marginLeft: collapse ? 80 : 250,
						paddingTop: 0,
						background: '#fff'
					}}>
					<Header
						className="site-layout-background"
						// style={{ background: "#fff" }}
						style={{
							background: '#fff',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}>
						{logeado ? (
							<div style={{ display: 'flex' }}>
								<SuperSearchNew header={true} />
							</div>
						) : null}

						<div>
							{logeado && tasksExpired ? (
								<div style={{ float: 'right', display: 'flex', alignItems: 'center' }} key="6">
									<Button style={{ border: 'none', marginRight: 0 }} onClick={() => showDrawer()}>
										{' '}
										<Badge
											count={
												tasksExpired !== undefined &&
												tasksExpired.filter((task) => task.notification && task.notified).length
											}
											size="small">
											<img src={'/images/RemindIconBlue.png'} />
										</Badge>
									</Button>

									<Popover
										visible={aircallVisible}
										open={aircallVisible}
										destroyTooltipOnHide={false}
										trigger="click"
										onClick={() => {
											dispatch(showAircall(!aircallVisible));
										}}
										content={
											<iframe
												id="aircall-phone"
												allow="microphone; autoplay; clipboard-read; clipboard-write"
												src="https://phone.aircall.io?integration=generic"
												style={{
													width: 376,
													height: 666,
													border: 'none'
												}}></iframe>
										}>
										<Button style={{ border: 'none' }}>
											<img
												src={`/images/aircall/aircall-${aircallStatusColor}.svg`}
												alt=""
												style={{ width: 30, marginTop: -4 }}></img>
										</Button>
									</Popover>
									<Avatar
										icon={iconUser}
										className="lang-avatar"
										size="medium"
										style={{ marginRight: 5 }}
									/>
									<Dropdown overlay={menu}>
										<a style={{ color: '#2F4858' }}>
											{' ' + agent} <Icon type="down" />
										</a>
									</Dropdown>
								</div>
							) : null}
						</div>
					</Header>

					<Content
						style={{
							margin: '0px 0px',
							overflow: 'initial',
							marginTop: '2px',
							background: '#fff'
						}}>
						<div
							className="site-layout-background"
							style={{ padding: 0, minHeight: 360, background: '#fff' }}>
							{props.children}
						</div>
					</Content>
					<Footer style={{ textAlign: 'center' }}>
						{'gibobs allbanks © ' + moment().format('YYYY')}
					</Footer>
				</Layout>
			</Layout>
		);
	} else {
		return <Login />;
	}
}

export default LayoutRoute;
